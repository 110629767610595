import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { animated, useSpring, config } from 'react-spring'
import Layout from '../components/layout'
import GridItem from '../components/grid-item'
import SEO from '../components/SEO'
import { ChildImageSharp } from '../types'

type PageProps = {
  data: {
    projects: {
      nodes: {
        title: string
        slug: string
        cover: ChildImageSharp
      }[]
    }
  }
}

const Area = styled(animated.div)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 30vw;

  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    grid-template-columns: 1fr;
    grid-auto-rows: 130vw;
  }

  ${GridItem} {
    margin: 2px;
    span {
      color: white;
      background: rgb(255,255,255);
      background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 0%, rgba(0,0,0,0) 33%, rgba(0,0,0,0.9682247899159664) 100%);
      padding: 10px; 
      font-weight: 700;
      font-size: ${props => props.theme.fontSizes[3]};
      text-shadow: 0px 2px 1px rgba(0, 0, 0, .8);
      @media (max-width: ${props => props.theme.breakpoints[3]}) {
        font-size: ${props => props.theme.fontSizes[1]};
      }
      transform: translateY(45px);
      transition: all 0.4s ease 0s;
      opacity: 0;
      @media (max-width: ${props => props.theme.breakpoints[2]}) {
        transform: translateY(0);
        opacity: 1;
      }
    }
    &:hover {
      span {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
`

const Index: React.FunctionComponent<PageProps> = ({ data: { projects } }) => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })
  return (
    <Layout>
      <SEO title="Portfolio | Lantern of the Soul" />
      <Area style={pageAnimation}>
        {projects.nodes.map(project => (
          <GridItem key={project.slug} to={project.slug} aria-label={`View portfolio project "${project.title}"`}>
            <Img fluid={project.cover.childImageSharp.fluid} />
            <span>{project.title}</span>
          </GridItem>
        ))}
      </Area>
    </Layout>
  )
}

export default Index

export const query = graphql`
  query Index {
    projects: allProjectsYaml {
      nodes {
        title
        slug
        cover {
          childImageSharp {
            fluid(quality: 95, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

